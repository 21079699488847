import React from 'react';

import { IGlobalServerRuntimeData } from '../../types/strapi/global';
import { ITechnologyPage } from '../../types/strapi/technology';
import { mediaUrl } from '../../utils';
import Footer from '../footer';
import Layout from '../layout';
import PageSection from '../PageSection';

import AboutTesting from './components/AboutTesting';
import DriveAlgorithms from './components/DriveAlgorithms';
import Principles from './components/Principles';
import Sensors from './components/Sensors';
import TechnologyBanner from './components/TechnologyBanner';
import TransportTypes from './components/TransportTypes';

interface IQueryData extends IGlobalServerRuntimeData {
    technologyPage: ITechnologyPage
}

interface IProps {
    data: IQueryData
}

const TechnologyPage = ({ data }: IProps) => {
    const {
        seo,
        technologyBanner,
        principles,
        transportTypeTitle,
        transportTypeDescription,
        transportTypeCard,
        sensorsBlock,
        driveAlgorithmTitle,
        driveAlgorithmDescription,
        driveAlgorithmVideoAccordeon,
        aboutTestingTitle,
        aboutTestingDescription,
        aboutTestingItem,
        footerText
    } = data.technologyPage;

    return (
        <Layout
            seo={{
                ...seo,
                shareImage: seo?.shareImage?.url ? mediaUrl(seo?.shareImage?.url) : undefined
            }}
            hasContactButton={false}
            navPanel={data.navPanel}
            global={data.global}
            respondForm={data.respondForm}
        >
            <PageSection removeAboveOffset={true} removeBelowOffset={true}>
                <TechnologyBanner data={technologyBanner} />
            </PageSection>

            <PageSection removeAboveOffset={true} removeBelowOffset={true}>
                <Principles data={principles} />
            </PageSection>

            <PageSection>
                <TransportTypes data={{ transportTypeTitle, transportTypeDescription, transportTypeCard }} />
            </PageSection>

            <PageSection>
                <Sensors data={sensorsBlock} />
            </PageSection>

            <PageSection>
                <DriveAlgorithms data={{ driveAlgorithmTitle, driveAlgorithmDescription, driveAlgorithmVideoAccordeon }} />
            </PageSection>

            <PageSection>
                <AboutTesting data={{ aboutTestingTitle, aboutTestingDescription, aboutTestingItem }} />
            </PageSection>

            <Footer header={footerText} data={data.footer} />
        </Layout>
    );
};

export default TechnologyPage;
