import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { ITechnologyPage } from '../../../../types/strapi/technology';
import { formatText } from '../../../../utils';
import GridWrapper from '../../../grid-wrapper';
import Heading from '../../../heading';
import Text from '../../../text';
import TransportTypeCard from '../../../TransportTypeCard';

import './index.css';

interface IProps {
    data: Pick<ITechnologyPage, 'transportTypeTitle' | 'transportTypeDescription' | 'transportTypeCard'>
}

const TransportTypes = ({ data }: IProps) => {
    const cn = useClassnames();

    const cssBlock = 'transport-types';

    return (
        <GridWrapper className={cn(`${cssBlock}__wrapper`)}>
            <Heading
                className={cn(`${cssBlock}__title`)}
                level={3}
                dangerouslySetInnerHTML={{ __html: formatText(data.transportTypeTitle) }}
            />

            <Text
                className={cn(`${cssBlock}__description`)}
                size={2}
                dangerouslySetInnerHTML={{ __html: data.transportTypeDescription }}
            />

            {data.transportTypeCard.map((card) => (
                <TransportTypeCard
                    key={card.title}
                    className={cn(`${cssBlock}__card`)}
                    cardData={card}
                />
            ))}
        </GridWrapper>

    );
};

export default TransportTypes;
