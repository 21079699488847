import React from 'react';

import { ITechnologyBanner } from '../../../../types/strapi/technology';
import HeadingBannerExtended from '../../../HeadingBannerExtended';

interface IProps {
    data: ITechnologyBanner
}

const TechnologyBanner = ({ data }: IProps) => {
    return (
        <HeadingBannerExtended
            title={data.title}
            description={data.description}
            image={data.image}
        />
    );
};

export default TechnologyBanner;
