import React from 'react';

import { useClassnames } from '../../hooks/use-classnames';
import { ITransportTypeCard } from '../../types/strapi/technology';
import LinkCard from '../LinkCard';
import style from '../LinkCard/index.css';
import { Picture } from '../Picture';

import './index.css';

interface ITransportTypeCardProps {
    cardData: ITransportTypeCard,
    className?: string,
    description?: string,
    onMouseEnter?: React.MouseEventHandler
}

const cssBlock = 'transport-type-card';

const TransportTypeCard: React.FC<ITransportTypeCardProps> = ({ cardData, description, className, onMouseEnter }) => {
    const cn = useClassnames(style);

    const cardImage = cardData.image;

    return (
        <LinkCard
            className={cn(cssBlock, className)}
            {...cardData}
            href={cardData.link}
            description={description || cardData.description}
            onMouseEnter={onMouseEnter}
        >
            {(cardImage?.desktopNormal || cardImage?.desktopSmall || cardImage?.tablet || cardImage?.mobile) && (
                <Picture
                    className={cn(`${cssBlock}__picture`)}
                    image={cardImage}
                    alt={cardImage.alt || ''}
                />
            )}
        </LinkCard>
    );
};

export default TransportTypeCard;
