import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { IPrinciple } from '../../../../types/strapi/technology';
import GridWrapper from '../../../grid-wrapper';
import Accordion from '../Accordion';

import './index.css';

interface IProps {
    data: Array<IPrinciple>
}

const Principles = ({ data }: IProps) => {
    const cn = useClassnames();

    return (
        <GridWrapper>
            <ul className={cn('principles')}>
                {data.map(({ title, description }) => (
                    <Accordion
                        key={title}
                        title={title}
                        description={description}
                        headingSize="m"
                        withColumns={true}
                        withBorderTop={true}
                    />
                ))}
            </ul>
        </GridWrapper>
    );
};

export default Principles;
