import React from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { ITechnologyPage } from '../../../../types/strapi/technology';
import { formatText, mediaUrl } from '../../../../utils';
import { useIsClient } from '../../../../utils/hooks/useIsClient';
import { useSourceByScreenType } from '../../../../utils/hooks/useSourceByScreen';
import GridWrapper from '../../../grid-wrapper';
import Heading from '../../../heading';
import Text from '../../../text';

import VideoCard from './components/VideoCard';

import style from './index.css';

interface IProps {
    data: Pick<ITechnologyPage, 'aboutTestingTitle' | 'aboutTestingDescription' | 'aboutTestingItem'>
}

const AboutTesting = ({ data }: IProps) => {
    const cn = useClassnames(style);

    const cssBlock = 'about-testing';

    const isClient = useIsClient();

    const tests = data.aboutTestingItem.map((test) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const source = useSourceByScreenType<string>({
            desktopSmall: test.desktopNormal.sources[0].url,
            mobile      : test.mobile.sources[0].url
        }, test.desktopNormal.sources[0].url);
        return {
            title      : test.title,
            description: test.description,
            preview    : mediaUrl(test.preview.url),
            video      : {
                src : mediaUrl(source),
                type: 'mp4'
            },
            poster: test.desktopNormal.preview?.url ? mediaUrl(test.desktopNormal.preview.url) : ''
        };
    });

    return (
        <GridWrapper className={cn(`${cssBlock}__wrapper`)}>

            <Heading
                className={cn(`${cssBlock}__title`)}
                level={3}
                dangerouslySetInnerHTML={{ __html: formatText(data.aboutTestingTitle) }}
            />

            <Text
                className={cn(`${cssBlock}__description`)}
                size={2}
                dangerouslySetInnerHTML={{ __html: data.aboutTestingDescription }}
            />

            <ul className={cn(`${cssBlock}__tests`)} key={`isClient-${isClient}`}>
                {
                    tests.map(({ title, description, video, poster, preview }) => (
                        <li key={title}>
                            <VideoCard
                                key={title}
                                className={cn(`${cssBlock}__test`)}
                                title={title}
                                description={description}
                                // video={video}
                                poster={poster}
                                preview={preview}
                            />
                        </li>
                    ))
                }
            </ul>
        </GridWrapper>
    );
};

export default AboutTesting;
