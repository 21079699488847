import React, { useState } from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { ISensorsBlock } from '../../../../types/strapi/technology';
import GridWrapper from '../../../grid-wrapper';
import { Picture } from '../../../Picture';
import Accordion from '../Accordion';

import Swiper from './components/Swiper';

import './index.css';

interface IProps {
    data: ISensorsBlock
}

const Sensors = ({ data }: IProps) => {
    const cn = useClassnames();
    const [openedAccordion, setOpenedAccordion] = useState(data.sensors[0].code);

    const cssBlock = 'sensors';

    const handleAccordionClick = (accordionId: string) => {
        if(accordionId === openedAccordion) {
            setOpenedAccordion('');
        } else {
            setOpenedAccordion(accordionId);
        }
    };

    return (
        <div className={cn(`${cssBlock}__wrapper`)}>
            <GridWrapper className={cn(`${cssBlock}__wrapper-inner`)}>
                <ul className={cn(cssBlock)}>
                    {data.sensors.map(({ code, title, description }, index) => (
                        <Accordion
                            code={code}
                            key={code}
                            title={title}
                            color="white"
                            description={description}
                            headingSize={index === 0 ? 'm' : 's'}
                            isOpened={openedAccordion === code}
                            onClick={handleAccordionClick}
                            withBorderTop={index > 0}
                        />
                    ))}
                </ul>

                <Swiper className={cn(`${cssBlock}__swiper`)} slides={data.sensors} onSlideChange={setOpenedAccordion} />

                <div className={cn(`${cssBlock}__image-wrapper`)}>
                    <Picture
                        key="base"
                        className={cn(
                            `${cssBlock}__image`,
                            {
                                [`${cssBlock}__image_visible`]     : openedAccordion !== 'cameras',
                                [`${cssBlock}__image_zoomed`]      : openedAccordion === 'lidars' || openedAccordion === 'radars',
                                [`${cssBlock}__image_extra-zoomed`]: openedAccordion === 'lidars'
                            }
                        )}
                        image={data.image}
                        alt={data.image?.alt || ''}
                    />
                    {data.sensors.map(({ code, image }) => (
                        <Picture
                            key={code}
                            className={cn(
                                `${cssBlock}__image`,
                                {
                                    [`${cssBlock}__image-cameras`]     : code === 'cameras',
                                    [`${cssBlock}__image_visible`]     : openedAccordion === code,
                                    [`${cssBlock}__image_zoomed`]      : openedAccordion === 'lidars' || openedAccordion === 'radars',
                                    [`${cssBlock}__image_extra-zoomed`]: openedAccordion === 'lidars'
                                }
                            )}
                            image={image}
                            alt={image?.alt || ''}
                        />
                    ))}
                </div>
            </GridWrapper>
        </div>
    );
};

export default Sensors;
