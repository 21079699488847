import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import React, { useRef, useState } from 'react';

import { SCREEN_EDGES } from '../../../../../../constants';
import { useClassnames } from '../../../../../../hooks/use-classnames';
import Heading from '../../../../../heading';
import Text from '../../../../../text';
import VideoPlayer, { TVideo } from '../../../../../VideoPlayer';

import './index.css';

interface IProps {
    className?: string,
    title: string,
    description: string,
    video?: TVideo,
    poster: string,
    preview: string
}

const VideoCard = ({ className, title, description, video, poster, preview }: IProps) => {
    const cn = useClassnames();
    const cssBlock = 'video-card';
    const videoRef = useRef<HTMLDivElement>(null);
    const [isVideoMaximal, setIsVideoMaximal] = useState(false);

    const handleVideoSizeChange = (isFullscreen: boolean) => {
        if(window.outerWidth <= SCREEN_EDGES.tablet - 1 && videoRef.current) {
            if(isFullscreen) {
                disableBodyScroll(videoRef.current);
            } else {
                enableBodyScroll(videoRef.current);
            }
        }
        setIsVideoMaximal(isFullscreen);
    };

    return (
        <div className={cn(className, `${cssBlock}__wrapper`)} ref={videoRef}>
            <Heading
                className={cn(`${cssBlock}__title`)}
                level={4}
                dangerouslySetInnerHTML={{ __html: title }}
            />

            <Text
                className={cn(`${cssBlock}__description`)}
                size={4}
                dangerouslySetInnerHTML={{ __html: description }}
            />

            <div className={cn(`${cssBlock}__false-video-block`)} />

            <VideoPlayer
                className={cn(`${cssBlock}__video`, { [`${cssBlock}__video_maximal`]: isVideoMaximal })}
                video={video}
                poster={poster}
                preview={preview}
                previewClassName={cn(`${cssBlock}__video-poster`)}
                videoPlayerClassName={cn(`${cssBlock}__video-player`)}
                preload="metadata"
                controlsType="advanced"
                onVideoSizeChange={handleVideoSizeChange}
                playsInline={true}
                loop={true}
            />

        </div>
    );
};

export default VideoCard;
