import React, { useRef, useState } from 'react';

import { useClassnames } from '../../../../hooks/use-classnames';
import { ITechnologyPage } from '../../../../types/strapi/technology';
import { formatText, mediaUrl } from '../../../../utils';
import { useIsClient } from '../../../../utils/hooks/useIsClient';
import { useSourceByScreenType } from '../../../../utils/hooks/useSourceByScreen';
import GridWrapper from '../../../grid-wrapper';
import Heading from '../../../heading';
import Text from '../../../text';
import Accordion from '../Accordion';

import style from './index.css';


interface IProps {
    data: Pick<ITechnologyPage, 'driveAlgorithmTitle' | 'driveAlgorithmDescription' | 'driveAlgorithmVideoAccordeon'>
}

const DriveAlgorithms = ({ data }: IProps) => {
    const videos = data.driveAlgorithmVideoAccordeon.map((video) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const source = useSourceByScreenType<string>({
            desktopSmall: video.desktopSmall.sources[0].url,
            mobile      : video.mobile.sources[0].url
        }, video.desktopSmall.sources[0].url);
        return {
            code  : video.code,
            src   : mediaUrl(source),
            poster: video.desktopSmall.preview?.url ? mediaUrl(video.desktopSmall.preview.url) : ''
        };
    });

    const cn = useClassnames(style);
    const [openedAccordion, setOpenedAccordion] = useState(data.driveAlgorithmVideoAccordeon[0].code);
    const isMobile = useIsClient();

    const cssBlock = 'drive-algorithms';

    const handleAccordionClick = (accordionId: string) => {
        if(accordionId === openedAccordion) {
            setOpenedAccordion('');
        } else {
            setOpenedAccordion(accordionId);
        }
    };

    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <GridWrapper ref={containerRef}>

            <Heading
                className={cn(`${cssBlock}__title`)}
                level={3}
                dangerouslySetInnerHTML={{ __html: formatText(data.driveAlgorithmTitle) }}
            />

            <Text
                className={cn(`${cssBlock}__description`)}
                size={2}
                dangerouslySetInnerHTML={{ __html: data.driveAlgorithmDescription }}
            />

            <ul className={cn(`${cssBlock}__list`)}>
                {data.driveAlgorithmVideoAccordeon.map(({ code, title, description }, index) => (
                    <Accordion
                        code={code}
                        key={code}
                        title={title}
                        description={description}
                        headingSize="s"
                        withBorderTop={index > 0}
                        isOpened={openedAccordion === code}
                        onClick={handleAccordionClick}
                    />
                ))}
            </ul>

            <div className={cn(`${cssBlock}__image-wrapper`)}>
                {videos.map((video, index) => (
                    <video
                        className={cn(`${cssBlock}__image`, { [`${cssBlock}__image_visible`]: video.code === 'perception' || openedAccordion === video.code })}
                        muted={true}
                        loop={true}
                        autoPlay={true}
                        playsInline={true}
                        preload="metadata"
                        poster={video.poster}
                        key={`${index}_${isMobile}`}
                    >
                        <source src={video.src} type="video/mp4" />
                    </video>
                ))}

            </div>
        </GridWrapper>
    );
};

export default DriveAlgorithms;
