import React, { useState, useEffect, useRef } from 'react';

import Heading from '../../../../components/heading';
import Text from '../../../../components/text';
import { useClassnames } from '../../../../hooks/use-classnames';
import MinusIcon from '../../../../images/technology/accordion/minus.inline.svg';
import PlusIcon from '../../../../images/technology/accordion/plus.inline.svg';

import './index.css';

type TProps = {
    title: string,
    description: string,
    headingSize: 's' | 'm' | 'l',
    descriptionClassName?: string,
    color?: 'white' | 'black',
    withColumns?: boolean,
    withBorderTop: boolean,
    isOpened?: boolean
} & ({
    code: string,
    onClick?: (code: string) => void
} | {
    code?: never,
    onClick?: never
});

const Accordion: React.FC<TProps> = ({ code, title, description, descriptionClassName, children, headingSize = 'm', color = 'black', withColumns, withBorderTop, isOpened: isOpenedFromProps = false, onClick }) => {
    const cn = useClassnames();
    const [isOpened, setIsOpened] = useState(isOpenedFromProps);
    const [collapseHeight, setCollapseHeight] = useState(0);

    const collapseRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const collapseEl = collapseRef.current;
        if(collapseEl) {
            setCollapseHeight(isOpened ? collapseEl.scrollHeight : 0);
        }
    }, [isOpened]);

    useEffect(() => {
        setIsOpened(isOpenedFromProps);
    }, [isOpenedFromProps]);

    const cssBlock = 'accordion';
    /* eslint-disable @typescript-eslint/no-magic-numbers */
    const headingLevelNumberBySize = {
        's': 4 as const,
        'm': 3 as const,
        'l': 2 as const
    };
    /* eslint-enable @typescript-eslint/no-magic-numbers */

    const handleOnButtonClick = () => {
        setIsOpened(!isOpened);

        if(code) {
            onClick?.(code);
        }
    };

    return (
        <li
            className={cn(cssBlock, {
                [`${cssBlock}_with_border-top`]: withBorderTop,
                [`${cssBlock}_color_white`]    : color === 'white',
                [`${cssBlock}_color_black`]    : color === 'black',
                [`${cssBlock}_small`]          : headingSize === 's',
                [`${cssBlock}_medium`]         : headingSize === 'm',
                [`${cssBlock}_large`]          : headingSize === 'l',
                [`${cssBlock}_closed`]         : !isOpened
            })}
        >
            <button
                className={cn(`${cssBlock}__header`)}
                type="button"
                onClick={handleOnButtonClick}
                aria-label={isOpened ? 'Свернуть аккордеон' : 'Развернуть аккордеон'}
            >
                <Heading
                    className={cn(`${cssBlock}__heading`)}
                    level={headingLevelNumberBySize[headingSize]}
                    dangerouslySetInnerHTML={{ __html: title }}
                />

                <div className={cn(`${cssBlock}__icon-wrapper`)}>
                    {
                        isOpened
                            ? <MinusIcon className={cn(`${cssBlock}__icon`)} />
                            : <PlusIcon className={cn(`${cssBlock}__icon`)} />
                    }
                </div>
            </button>
            <div
                ref={collapseRef}
                style={{ maxHeight: `${collapseHeight}px` }}
                className={cn(`${cssBlock}__collapse`)}
            >
                <Text
                    className={cn(
                        `${cssBlock}__description`,
                        { [`${cssBlock}__description_with-columns`]: withColumns },
                        descriptionClassName
                    )}
                    dangerouslySetInnerHTML={{ __html: description }}
                    size={4}
                />
                {children}
            </div>
        </li>
    );
};

export default Accordion;
