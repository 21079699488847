import React, { useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { useClassnames } from '../../../../../../hooks/use-classnames';
import { usePreventVerticalTouchScroll } from '../../../../../../utils/hooks/usePreventVerticalTouchScroll';
import Heading from '../../../../../heading';
import Text from '../../../../../text';

import './index.css';

interface ISlide {
    code: string,
    title: string,
    description: string
}

interface IProps {
    className?: string,
    slides: Array<ISlide>,
    onSlideChange?: (slideId: string) => void
}

const swipeThreshold = 20;
const primaryHeadingSize = 2;
const secondaryHeadingSize = 4;

const Swiper = ({ className, slides, onSlideChange }: IProps) => {
    const cn = useClassnames();
    const cssBlock = 'sb-swiper';
    const containerRef = useRef<HTMLDivElement>(null);

    usePreventVerticalTouchScroll(containerRef, swipeThreshold);

    const handleSlideChange = (index: number) => {
        if(index >= 0) {
            const activeSlideId = slides[index].code;

            onSlideChange?.(activeSlideId);
        }
    };

    return (
        <div className={cn(className, `${cssBlock}__wrapper`)} ref={containerRef}>
            <Carousel
                className={cn(`${cssBlock}__carousel`)}
                renderIndicator={(onClickHandler, isSelected) => (
                    <span
                        className={cn(`${cssBlock}__bullet`, { [`${cssBlock}__bullet_active`]: isSelected })}
                        onClick={onClickHandler}
                    />)}
                onChange={handleSlideChange}
                showArrows={false}
                showStatus={false}
                infiniteLoop={true}
                emulateTouch={true}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={swipeThreshold}
            >
                {slides.map(({ title, description }, index) => (
                    <div className={cn(`${cssBlock}__slide`)} key={title}>

                        <Heading className={cn(`${cssBlock}__slide-title`)} level={index === 0 ? primaryHeadingSize : secondaryHeadingSize}>
                            {title}
                        </Heading>

                        <Text
                            className={cn(`${cssBlock}__slide-description`)}
                            dangerouslySetInnerHTML={{ __html: description }}
                            size={4}
                        />
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Swiper;
